export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST'

export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS'

export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL'

export const ORDER_CREATE_RESET = 'ORDER_CREATE_RESET'

export const ORDER_DETAILS_REQUEST = 'ORDER_DETAILS_REQUEST'
export const ORDER_DETAILS_SUCCESS = 'ORDER_DETAILS_SUCCESS'
export const ORDER_DETAILS_FAIL = 'ORDER_DETAILS_FAIL'

export const ORDER_PAY_REQUEST = 'ORDER_PAY_REQUEST'
export const ORDER_PAY_SUCCESS = 'ORDER_PAY_SUCCESS'
export const ORDER_PAY_FAIL = 'ORDER_PAY_FAIL'
export const ORDER_PAY_RESET = 'ORDER_PAY_RESET'

export const MP_REQUEST = 'MP_REQUEST'
export const MP_SUCCESS = 'MP_SUCCESS'
export const MP_FAIL = 'MP_FAIL'
export const MP_RESET = 'MP_RESET'